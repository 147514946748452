import Cookies from 'js-cookie';

// Utility class for managing cookies across subdomains
class CookieManager {
  /**
   * Set a cookie with a shared domain configuration
   * @param {string} name - Name of the cookie
   * @param {string} value - Value of the cookie
   * @param {Object} options - Cookie options
   */
  static set(name, value, options = {}) {
    const domain = this.getBaseDomain();

    const defaultOptions = {
      domain: domain, 
      path: '/',
      sameSite: 'Lax',
      secure: process.env.NODE_ENV === 'production'
    };

    const cookieOptions = { ...defaultOptions, ...options };
    Cookies.set(name, value, cookieOptions);
  }

  /**
   * Get a cookie value
   * @param {string} name - Name of the cookie
   * @returns {string|undefined} Cookie value
   */
  static get(name) {
    return Cookies.get(name);
  }

  /**
   * Remove a cookie
   * @param {string} name - Name of the cookie
   */
  static remove(name) {
    const domain = this.getBaseDomain();
    Cookies.remove(name, { domain: domain, path: '/' });
  }

  /**
   * Extract the base domain from the current hostname
   * @returns {string} Base domain
   */
  static getBaseDomain() {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    if (parts.length <= 2) return hostname;
    return parts.slice(-2).join('.');
  }
}

export default CookieManager;
